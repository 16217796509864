import React from 'react';
import { useRouter } from 'next/router';
import { filter, flatten, isEmpty, map } from 'lodash';
import { GetStaticPaths, GetStaticProps, InferGetStaticPropsType } from 'next';

import {
  DEFAULT_LANG,
  getLangCountry,
  getRealLangCountry
} from '@/helpers/languages';
import { fetchNews } from '@/fetchers/news';
import { News as TNews } from '@/types/News';
import { HomeResponseData, fetchHome } from '@/fetchers/home';

import MyHead from '@/components/MyHead';
import Hero from '@/components/v2/Pages/Home/Hero';
import Section1 from '@/components/v2/Pages/Home/Section1';
import Section2 from '@/components/v2/Pages/Home/Section2';
import Section3 from '@/components/v2/Pages/Home/Section3';
import Cards from '@/components/v2/Pages/Home/Cards';
import Shoes from '@/components/v2/Pages/Home/Shoes';
import Awards from '@/components/v2/Pages/Home/Awards';
import News from '@/components/v2/Pages/Home/News';
import About from '@/components/v2/Pages/Home/About';
import { useAlternateUrls } from '@/helpers/seo';

export default function HomePage(
  props: InferGetStaticPropsType<typeof getStaticProps>
) {
  const router = useRouter();
  const { lang, country } = getLangCountry(router.query);

  const {
    layouts: {
      home_main_slider = [],
      home_banner_one = [],
      home_banner_two = []
    }
  } = props.home || {};

  const slidesLang = flatten(
    map(
      filter(home_main_slider, slide => slide.lang === lang),
      ({ sliders }) => sliders
    )
  );

  const slidesEN = flatten(
    map(
      filter(home_main_slider, slide => slide.lang === DEFAULT_LANG),
      ({ sliders }) => sliders
    )
  );

  const cards = filter([...home_banner_one, ...home_banner_two], banner => {
    return (
      Array.isArray(banner.storeview) &&
      banner.storeview.includes(country) &&
      banner.lang === lang
    );
  });

  const slides = !isEmpty(slidesLang) ? slidesLang : slidesEN;

  const alternateUrls = useAlternateUrls('');

  return (
    <>
      <MyHead alternates={alternateUrls}/>

      <Hero data={slides} />
      <Section3 />
      <Section1 />
      <Section2 />
      {/* <Cards data={cards} /> */}
      <Shoes />
      <Awards />
      <News data={props.news} />
      <About />
    </>
  );
}

export const getStaticPaths: GetStaticPaths = async () => {
  return {
    paths: [],
    fallback: 'blocking'
  };
};

export const getStaticProps: GetStaticProps<{
  home: HomeResponseData;
  news: TNews[];
}> = async ctx => {
  const params = getRealLangCountry(ctx.params);

  const [home, news] = await Promise.all([
    fetchHome(params),
    fetchNews(params)
  ]);

  return {
    props: {
      home,
      news
    },
    revalidate: 86_400
  };
};
