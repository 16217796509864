import 'swiper/css';
import 'swiper/css/scrollbar';

import clsx from 'clsx';
import Link from 'next/link';
import { Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { buttonVariants } from '../../UI/Button';
import { useTranslation } from 'react-i18next';

export default function Section2() {
  const { t } = useTranslation();

  const section = {
    title: t('P_HOME.SECTION-2.TITLE'),
    description: t('P_HOME.SECTION-2.DESCRIPTION'),
    cards: [
      {
        title: t('P_HOME.SECTION-2.CARDS.LOW.KEY'),
        content: t('P_HOME.SECTION-2.CARDS.LOW.TITLE'),
        href_man: '/collections/man/apparel-underwear/?activityintensity=01',
        href_woman:
          '/collections/woman/apparel-underwear/?activityintensity=01',
        temperature: 'low',
        gradient: 'bg-gradient-to-b from-50% from-[#5697AF] to-[#8AB4C2]',
        keys: [
          { name: t('P_HOME.SECTION-2.CARDS.LOW.KEY'), active: true },
          { name: t('P_HOME.SECTION-2.CARDS.MODERATE.KEY'), active: false },
          { name: t('P_HOME.SECTION-2.CARDS.HEAVY.KEY'), active: false }
        ]
      },
      {
        title: t('P_HOME.SECTION-2.CARDS.MODERATE.KEY'),
        content: t('P_HOME.SECTION-2.CARDS.MODERATE.TITLE'),
        href_man: '/collections/man/apparel-underwear/?activityintensity=02',
        href_woman:
          '/collections/woman/apparel-underwear/?activityintensity=02',
        temperature: 'medium',
        gradient: 'bg-gradient-to-b from-50% from-[#E3B06C] to-[#E7C596]',
        keys: [
          { name: t('P_HOME.SECTION-2.CARDS.LOW.KEY'), active: true },
          { name: t('P_HOME.SECTION-2.CARDS.MODERATE.KEY'), active: true },
          { name: t('P_HOME.SECTION-2.CARDS.HEAVY.KEY'), active: false }
        ]
      },
      {
        title: t('P_HOME.SECTION-2.CARDS.HEAVY.KEY'),
        content: t('P_HOME.SECTION-2.CARDS.HEAVY.TITLE'),
        href_man: '/collections/man/apparel-underwear/?activityintensity=03',
        href_woman:
          '/collections/woman/apparel-underwear/?activityintensity=03',
        temperature: 'high',
        gradient: 'bg-gradient-to-b from-50% from-[#BE7172] to-[#CE9C9B]',
        keys: [
          { name: t('P_HOME.SECTION-2.CARDS.LOW.KEY'), active: true },
          { name: t('P_HOME.SECTION-2.CARDS.MODERATE.KEY'), active: true },
          { name: t('P_HOME.SECTION-2.CARDS.HEAVY.KEY'), active: true }
        ]
      }
    ]
  };

  return (
    <section className="section full section-y-md space-y-md">
      <div className="section-wrapper mx-auto space-y-md">
        <div className="flex flex-col items-center text-center">
          <h2 className="h3 mx-auto">{section.title}</h2>
          <p className="text-body">{section.description}</p>
        </div>
      </div>
      <Swiper
        wrapperTag="ul"
        slidesPerView={1.2}
        spaceBetween={16}
        modules={[Scrollbar]}
        scrollbar={{ hide: false }}
        className="swiper-section-x md:max-w-7xl swiperScrollbar"
        breakpoints={{
          640: {
            slidesPerView: 1.2,
            spaceBetween: 16
          },
          768: {
            slidesPerView: 2.2,
            spaceBetween: 16
          },
          1280: {
            slidesPerView: 3,
            spaceBetween: 24
          }
        }}
      >
        {section.cards.map((section, idx) => (
          <SwiperSlide
            key={idx}
            tag="li"
            className={clsx(
              'flex flex-col max-w-[440px] text-white rounded space-y-4 p-6 md:p-8 md:space-y-10',
              section.gradient
            )}
          >
            <h3 className="flex-1 h5 text-center">
              {section.title} <br /> {t('P_HOME.SECTION-2.INTENSITY')}
            </h3>

            {/* <div>
              <div className="flex space-x-4">
                {section.keys.map(({ name, active }) => (
                  <div
                    key={name}
                    className="flex flex-1 flex-col items-center space-y-3"
                  >
                    {active ? (
                      <span className="inline-block h-10 w-full bg-white"></span>
                    ) : (
                      <div className="h-10 w-full space-x-0.5 overflow-hidden">
                        {range(0, 30).map((_, idx) => (
                          <span
                            key={idx}
                            className="inline-block h-full w-[1px] bg-white"
                          />
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <p className="h6 text-center mt-6">{section.temperature}</p>
            </div> */}

            <div className="text-center uppercase tracking-uyn">
              {section.content}
            </div>

            <div className="flex justify-center space-x-2">
              <Link href={section.href_man} aria-label={t('MAN')}>
                <a className={buttonVariants({ variant: 'light' })}>
                  {t('MAN')}
                </a>
              </Link>

              <Link href={section.href_woman} aria-label={t('WOMAN')}>
                <a className={buttonVariants({ variant: 'light' })}>
                  {t('WOMAN')}
                </a>
              </Link>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
}
