import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';

import { useMemo } from 'react';
import Image from 'next/future/image';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import { buttonVariants } from '../../UI/Button';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar } from 'swiper';

export default function Shoes() {
  const { t } = useTranslation();

  const section = useMemo(
    () => ({
      title: t('P_HOME.SHOES.TITLE'),
      cards: [
        {
          subtitle: {
            text: t('P_HOME.SHOES.CARDS.URBAN_TRAIL.SUBTITLE'),
            className: 'h5 text-uyn-urban-trail'
          },
          title: {
            text: t('P_HOME.SHOES.CARDS.URBAN_TRAIL.TITLE'),
            className: 'h5'
          },
          description: {
            text: t('P_HOME.SHOES.CARDS.URBAN_TRAIL.DESCRIPTION'),
            className: 'text-body'
          },
          src: '/v2/home/shoes/refresh-sett-24/2urbantrail.jpg',
          href_man: '/collections/man/shoes-urban-trail',
          href_woman: '/collections/woman/shoes-urban-trail'
        },
        {
          subtitle: {
            text: t('P_HOME.SHOES.CARDS.URBAN_OUTDOOR.SUBTITLE'),
            className: 'h5 text-uyn-urban-outdoor'
          },
          title: {
            text: t('P_HOME.SHOES.CARDS.URBAN_OUTDOOR.TITLE'),
            className: 'h5'
          },
          description: {
            text: t('P_HOME.SHOES.CARDS.URBAN_OUTDOOR.DESCRIPTION'),
            className: 'text-body'
          },
          src: '/v2/home/shoes/refresh-sett-24/2urbanoutdoor.jpg',
          href_man: '/collections/man/shoes-urban-outdoor',
          href_woman: '/collections/woman/shoes-urban-outdoor'
        },
        // {
        //   subtitle: {
        //     text: t('P_HOME.SHOES.CARDS.ACTIVE.SUBTITLE'),
        //     className: 'h5 text-uyn-active'
        //   },
        //   title: {
        //     text: t('P_HOME.SHOES.CARDS.ACTIVE.TITLE'),
        //     className: 'h5'
        //   },
        //   description: {
        //     text: t('P_HOME.SHOES.CARDS.ACTIVE.DESCRIPTION'),
        //     className: 'text-body'
        //   },
        //   src: '/v2/home/shoes/active2.jpg',
        //   href_man: '/collections/man/shoes-active',
        //   href_woman: '/collections/woman/shoes-active'
        // },
        {
          subtitle: {
            text: t('P_HOME.SHOES.CARDS.RUNNING.TITLE'),
            className: 'h5 text-uyn-active'
          },
          title: {
            text: t('P_HOME.SHOES.CARDS.RUNNING.SUBTITLE'),
            className: 'h5'
          },
          description: {
            text: t('P_HOME.SHOES.CARDS.RUNNING.DESCRIPTION'),
            className: 'text-body'
          },
          src: '/v2/home/shoes/refresh-sett-24/2running.jpg',
          href_man: '/collections/man/shoes-running',
          href_woman: '/collections/woman/shoes-running'
        },
        // {
        //   subtitle: {
        //     text: t('P_HOME.SHOES.CARDS.LIVING.SUBTITLE'),
        //     className: 'h5 text-uyn-living'
        //   },
        //   title: {
        //     text: t('P_HOME.SHOES.CARDS.LIVING.TITLE'),
        //     className: 'h5'
        //   },
        //   description: {
        //     text: t('P_HOME.SHOES.CARDS.LIVING.DESCRIPTION'),
        //     className: 'text-body'
        //   },
        //   src: '/v2/home/shoes/shoes-living.jpg',
        //   href_man: '/collections/man/shoes-living',
        //   href_woman: '/collections/woman/shoes-living'
        // },
        {
          subtitle: {
            text: t('P_HOME.SHOES.CARDS.CYCLING.SUBTITLE'),
            className: 'h5 text-uyn-cycling'
          },
          title: {
            text: t('P_HOME.SHOES.CARDS.CYCLING.TITLE'),
            className: 'h5'
          },
          description: {
            text: t('P_HOME.SHOES.CARDS.CYCLING.DESCRIPTION'),
            className: 'text-body'
          },
          src: '/v2/home/shoes/refresh-sett-24/2ciclyng.jpg',
          href_man: '/collections/man/shoes-biking',
          href_woman: '/collections/woman/shoes-biking'
        }
      ]
    }),
    []
  );

  return (
    <section className="section full section-y-md space-y-md">
      {/* <div className="section-wrapper">
        <h2 className="h3 mx-auto text-center">{section.title}</h2>
      </div> */}

      <Swiper
        wrapperTag="ul"
        slidesPerView={1.2}
        spaceBetween={16}
        modules={[Scrollbar, Navigation]}
        scrollbar={{ hide: false }}
        navigation={true}
        className="px-6 md:px-10 xl:px-16 swiperScrollbar"
        breakpoints={{
          768: {
            slidesPerView: 2.2
          },
          1280: {
            slidesPerView: 3.2
          },
          1536: {
            slidesPerView: 4.2
          }
        }}
      >
        {section.cards.map((category, idx) => (
          <SwiperSlide key={idx} tag="li" className="space-y-6 rounded">
            <Image
              alt={category.title.text}
              src={category.src}
              width={0}
              height={0}
              sizes="100vw"
              className="w-full h-auto rounded-uyn"
            />

            <div className="space-y-1">
              <div className={category.subtitle.className}>
                {category.subtitle.text}
              </div>
              <div className={category.title.className}>
                {category.title.text}
              </div>

              <p className={category.description.className}>
                {category.description.text}
              </p>
            </div>
            <div className="flex space-x-2">
              <Link href={category.href_man} aria-label={t('MAN')}>
                <a className={buttonVariants({ variant: 'dark' })}>
                  {t('MAN')}
                </a>
              </Link>
              <Link href={category.href_woman} aria-label={t('WOMAN')}>
                <a className={buttonVariants({ variant: 'dark' })}>
                  {t('WOMAN')}
                </a>
              </Link>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
}
