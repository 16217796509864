import 'swiper/css';
import 'swiper/css/scrollbar';

import { Scrollbar } from 'swiper';
import Image from 'next/future/image';
import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { buttonVariants } from '../../UI/Button';
import { useTranslation } from 'react-i18next';
import LinkTo from '@/components/LinkTo';

export default function Section1() {
  const { t } = useTranslation();

  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['-600px', '600px']
  });
  const img1y = useTransform(scrollYProgress, [0, 1], ['-6%', '6%']);
  const img2y = useTransform(scrollYProgress, [0, 1], ['-8%', '6%']);
  const img3y = useTransform(scrollYProgress, [0, 1], ['2%', '-6%']);

  const cards = [
    {
      title: t('P_HOME.SECTION-1.CARDS.NATURE.TITLE'),
      description: t('P_HOME.SECTION-1.CARDS.NATURE.DESCRIPTION'),
      src: '/v2/home/section-2/section-2_1.jpg'
    },
    {
      title: t('P_HOME.SECTION-1.CARDS.BIOTECHNOLOGY.TITLE'),
      description: t('P_HOME.SECTION-1.CARDS.BIOTECHNOLOGY.DESCRIPTION'),
      src: '/v2/home/section-2/section-2_2.jpg'
    },
    {
      title: t('P_HOME.SECTION-1.CARDS.SCIENCE.TITLE'),
      description: t('P_HOME.SECTION-1.CARDS.SCIENCE.DESCRIPTION'),
      src: '/v2/home/section-2/section-2_3.jpg'
    }
  ];

  return (
    <section
      className="section full section-y-md space-y-md bg-sand-100"
      ref={ref}
    >
      <div className="section-wrapper space-y-md">
        <div>
          <div className="h1 text-center text-sand-300">
            {t('P_HOME.SECTION-1.TITLE')}
          </div>
        </div>

        <div className="grid gap-8 md:grid-cols-2 md:gap-12 xl:gap-20">
          <div className="md:col-span-6 col-span-12 relative h-fit">
            <div className="overflow-hidden rounded-uyn">
              <Image
                src="/v2/home/biot.jpg"
                alt=""
                width={0}
                height={0}
                sizes="100vw"
                className="w-full h-auto"
              />
            </div>
            {/* <motion.div
              style={{ y: img1y }}
              transition={{ type: 'spring', stiffness: 100 }}
            >
              <Image
                src="/v2/home/section-2/level2.jpg"
                alt=""
                width={0}
                height={0}
                sizes="100vw"
                className="w-full max-w-[55%] ml-auto relative z-20"
              />
            </motion.div>

            <motion.div
              style={{ y: img2y }}
              transition={{ type: 'spring', stiffness: 100 }}
            >
              <Image
                src="/v2/home/section-2/level5.jpg"
                alt=""
                width={0}
                height={0}
                sizes="100vw"
                className="w-full max-w-[50%] mt-[-35%] relative z-30"
              />
            </motion.div>

            <motion.div
              style={{ y: img3y }}
              transition={{ type: 'spring', stiffness: 100 }}
            >
              <Image
                src="/v2/home/section-2/level3.jpg"
                alt=""
                width={0}
                height={0}
                sizes="100vw"
                className="w-full max-w-[45%] mt-[-25%] ml-auto mr-[10%] relative z-10"
              />
            </motion.div> */}
          </div>

          <div className="flex flex-col items-center self-center md:items-start md:col-span-5 md:col-start-8 col-span-12">
            <div className="h4 text-sand-300">
              {t('P_HOME.SECTION-1.ARTICLE.TITLE')}
            </div>

            <p className="text-body max-w-lg text-center md:text-left mt-4">
              {t('P_HOME.SECTION-1.ARTICLE.DESCRIPTION')}
            </p>

            <div className="mt-8">
              <LinkTo
                href="/biotech"
                aria-label={t('P_HOME.SECTION-1.ARTICLE.CTA')}
                className={buttonVariants({ variant: 'dark' })}
              >
                {t('P_HOME.SECTION-1.ARTICLE.CTA')}
              </LinkTo>
            </div>
          </div>
        </div>
      </div>

      <Swiper
        wrapperTag="ul"
        slidesPerView={1.2}
        spaceBetween={16}
        modules={[Scrollbar]}
        scrollbar={{ hide: false }}
        className="swiper-section-x swiperScrollbar"
        breakpoints={{
          768: {
            slidesPerView: 2.2
          },
          1024: {
            slidesPerView: 3
          }
        }}
      >
        {cards.map((card, idx) => (
          <SwiperSlide
            key={idx}
            tag="li"
            className="rounded-uyn overflow-hidden"
          >
            <LinkTo href="/biotech">
              <Image
                src={card.src}
                alt=""
                width={0}
                height={0}
                sizes="100vw"
                className="w-full h-auto"
              />

              <div className="absolute inset-0 flex flex-col justify-between text-white p-6 md:p-8">
                <h3 className="h4">{card.title}</h3>

                <p className="text-body">{card.description}</p>
              </div>
            </LinkTo>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
}
