import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';

import React, { useMemo } from 'react';
import Image from 'next/future/image';
import Link from 'next/link';
import { buttonVariants } from '../../UI/Button';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar } from 'swiper';
import clsx from 'clsx';

export default function Section4() {
  const { t } = useTranslation();

  const section = useMemo(
    () => ({
      title: t('P_HOME.SECTION-3.TITLE'),
      cards: [
        {
          title: t('SPORTS.WINTER_SPORTS'),
          men_href: '/collections/man/sports-wintersports',
          woman_href: '/collections/woman/sports-wintersports',
          src: '/v2/home/sports/refresh-sett-24/1wintee.jpg'
        },
        {
          title: t('SPORTS.OUTDOOR'),
          men_href: '/collections/man/sports-outdoor',
          woman_href: '/collections/woman/sports-outdoor',
          src: '/v2/home/sports/refresh-sett-24/1outdoor.jpg'
        },
        {
          title: t('SPORTS.RUNNING'),
          men_href: '/collections/man/sports-running',
          woman_href: '/collections/woman/sports-running',
          src: '/v2/home/sports/refresh-sett-24/1running.jpg'
        },
        {
          title: t('SPORTS.CYCLING'),
          men_href: '/collections/man/sports-cycling',
          woman_href: '/collections/woman/sports-cycling',
          src: '/v2/home/sports/refresh-sett-24/1ciclyng.jpg'
        },
        {
          title: t('SPORTS.FITNESS_TRAINING'),
          men_href: '/collections/man/sports-fitness-training',
          woman_href: '/collections/woman/sports-fitness-training',
          src: '/v2/home/sports/fitness2.jpg'
        }

        // {
        //   title: t('SPORTS.PADEL'),
        //   men_href: '/collections/man/sports-padel',
        //   woman_href: '',
        //   src: '/v2/home/sports/padel.jpg'
        // },
        // {
        //   title: t('SPORTS.YACHTING'),
        //   men_href: '/collections/man/sports-yachting',
        //   woman_href: '',
        //   src: '/v2/home/sports/yatching2.jpg'
        // },
        // {
        //   title: t('SPORTS.GOLF'),
        //   men_href: '/collections/man/sports-golf',
        //   woman_href: '',
        //   src: '/v2/home/sports/golf.jpg'
        // }
      ]
    }),
    []
  );

  return (
    <section className="section full section-y-md bg-sand-100 space-y-md">
      <div className="section-wrapper mb-12">
        <div className="h3 mx-auto text-center">{section.title}</div>
      </div>

      <Swiper
        wrapperTag="ul"
        slidesPerView={1.2}
        spaceBetween={16}
        navigation={true}
        modules={[Scrollbar, Navigation]}
        scrollbar={{ hide: false }}
        className="px-6 md:px-10 xl:px-16 swiperScrollbar"
        breakpoints={{
          768: {
            slidesPerView: 2.2
          },
          1280: {
            slidesPerView: 3.2
          },
          1536: {
            slidesPerView: 4.2
          }
        }}
      >
        {section.cards.map((category, idx) => (
          <SwiperSlide key={idx} tag="li" className="relative">
            <Image
              src={category.src}
              alt=""
              width={0}
              height={0}
              sizes="100vw"
              className="w-full h-auto object-cover rounded-uyn"
            />

            <span className="absolute inset-0 bg-gradient-to-b from-60% from-transparent to-black/80 rounded-uyn" />

            <div className="group absolute inset-0 flex flex-col justify-end items-center space-y-6 text-white py-8 md:py-12">
              <div className="h5">{category.title}</div>

              <div className="flex justify-center xl:hidden xl:group-hover:flex flex-wrap">
                {category.men_href.trim() != '' && (
                  <Link href={category.men_href} aria-label={t('MAN')}>
                    <a
                      className={clsx(
                        buttonVariants({ variant: 'light' }),
                        'mx-1'
                      )}
                    >
                      {t('MAN')}
                    </a>
                  </Link>
                )}

                {category.woman_href.trim() != '' && (
                  <Link href={category.woman_href} aria-label={t('WOMAN')}>
                    <a
                      className={clsx(
                        buttonVariants({ variant: 'light' }),
                        'mx-1'
                      )}
                    >
                      {t('WOMAN')}
                    </a>
                  </Link>
                )}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
}
